body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.pizza-image {
 
  justify-self: center;
  height: 12rem;
  width: auto;
}

.div_home_image {
  position: relative;
  float: left;
  width: 45%;
  padding: 0 10px;
}
.home_image {
  
  height: 580px;
  width: auto;
}
.home_paragraph {
  position: relative;
  padding: 100px 100px 100px 100px;
  float:left;
  text-align: left;
  margin-right: auto;
  width: 55%;
}

td {
  text-align: center;
}

table {
  margin: 0 auto;
}

.container {
  padding: 80px 120px;
}
textarea {
  resize: none;
}
.div_contact{
  position: relative;
  height: 500px;
  bottom: 0px;
}